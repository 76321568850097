import React, { useState, useEffect } from 'react';
import { Modal, Button, Card, Spin, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';

const { Title, Paragraph, Text } = Typography;

const CrmExportModal = ({ visible, onClose, onConfirm, integrationStatus, numData }) => {
  const navigate = useNavigate();

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="export"
          type="primary"
          onClick={onConfirm}
          disabled={!integrationStatus?.hubspot && !integrationStatus?.salesforce}
        >
          Confirm Export
        </Button>,
      ]}
      width={800}
    >
      <Card>
        {integrationStatus?.hubspot ? (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src="/hub_spot_2.webp" alt="hubspot icon" className="upload-icon" />
            <Typography>
                <Text style={{ fontSize: '16px' }}>Pushing <strong>{numData}</strong> records to HubSpot</Text>
                <br />
            </Typography>
            </div>
            <div>
                <br />
                <Paragraph>
                <Text strong>1. Finding or Creating Contacts:</Text>
                <br />
                We will search for existing contacts in HubSpot. If a contact does not exist, we will create a new one.
                </Paragraph>
                <Paragraph>
                <Text strong>2. Creating Custom Fields:</Text>
                <br />
                We will create custom fields in HubSpot to store additional information.
                <ul>
                    <li>
                    <Text strong>Multiline Fields:</Text>
                    <br />
                    These fields can contain multiple lines of text. The fields include:
                    <ul>
                        <li>insight_1 to .... insight_10</li>
                        <li>positive_signals</li>
                        <li>negative_signals</li>
                        <li>email_message</li>
                    </ul>
                    </li>
                    <li>
                    <Text strong>Single Line Fields:</Text>
                    <br />
                    These fields contain a single line of text. The fields include:
                    <ul>
                        <li>linkedin_url</li>
                        <li>dealight_relevance</li>
                        <li>email_subject</li>
                    </ul>
                    </li>
                </ul>
                </Paragraph>
                <Paragraph>
                After creating these custom fields, we will push the corresponding data into HubSpot to ensure all relevant information is captured and stored.
                </Paragraph>
            </div>
          </>
        ) : integrationStatus?.salesforce ? (
          <>
            <img src={'/hub_spot_2.webp'} alt="Salesforce" style={{ width: 50, marginBottom: 20 }} />
            <Title level={4}>Integrated with Salesforce</Title>
            <Paragraph>
              You have successfully integrated with Salesforce. Click the button below to export your leads to Salesforce.
            </Paragraph>
          </>
        ) : (
          <>
            <Title level={4}>No CRM Integration Found</Title>
            <Paragraph>
              No CRM integration was found. Please go to the integration page to set up your CRM integration.
            </Paragraph>
            <Button type="link" onClick={() => navigate('/settings/integrations')}>Go to Integration Page</Button>
          </>
        )}
      </Card>      
    </Modal>
  );
};

export default CrmExportModal;
