import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button, Divider } from 'antd';
import { UploadOutlined, OrderedListOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import useProfilePageHook from '../ProfilePage/ProfilePageHook';

import ImportCSVModal from '../LeadsPage/ImportCSVModal';

const SetupOverview = () => {
  const navigate = useNavigate();
  const [fileName, setFileName] = useState('');
  const { generationCredit, qualificationCredit, fetchUserProfile } = useProfilePageHook();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchUserProfile();
  }, []);  


  const handleGenerateLeadsClick = () => {
    navigate('/campaign/setup/list'); // Replace with your specific route
  };

  const handleCSVSubmit = (data, uploadedFileName) => {
    setFileName(uploadedFileName);
    navigate('/campaign/setup/import', { state: { data, fileName: uploadedFileName } });
  };

  const handleEditClick = () => {
    setShowModal(true);
};  

  return (
    <Row gutter={16} style={{ justifyContent: 'center', padding: '20px' }}>
      <Col span={10}>
        <Card bordered={false} style={{ textAlign: 'center' }}>
          <OrderedListOutlined style={{ fontSize: '48px', color: '#1890ff' }} />
          <h2>Generate Leads</h2>
          <p>Automatically generate potential leads based on your criteria.</p>
          <Button type="primary" onClick={handleGenerateLeadsClick}>
            Generate Leads
          </Button>
          <Divider />
          <p>Generation Credits left: {generationCredit}</p>
        </Card>
      </Col>

      <Col span={10}>
        <Card bordered={false} style={{ textAlign: 'center' }}>
          <UploadOutlined style={{ fontSize: '48px', color: '#1890ff' }} />
          <h2>Import Leads</h2>
          <p>
            You can import up to 100,000 rows at a time. Select CSV File to see more details.
          </p>
          <Button type="primary" onClick={handleEditClick}>Select CSV File</Button>
          <ImportCSVModal onSubmit={handleCSVSubmit} visible={showModal} setVisible={setShowModal} />
          <Divider />
          <p>Qualification Credits left: {qualificationCredit}</p>
        </Card>
      </Col>
    </Row>
  );
};

export default SetupOverview;
