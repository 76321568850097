import React, { useState, useEffect } from 'react';
import { Input, Button, Form, Card, Switch, Spin } from 'antd';
import useProfilePageHook from '../ProfilePage/ProfilePageHook';

const AdminPage = () => {
  const { 
    cleanupSessions, 
    addAllowedUser, 
    setUserCredits, 
    profile, 
    retryFailedJobs, 
    exportCampaign, 
    importCampaign, 
    setPremiumUser, 
    fetchUserProfile,
    addUserTag
  } = useProfilePageHook();
  
  const [email, setEmail] = useState('');
  const [tagName, setTagName] = useState('');

  const [credits, setCredits] = useState({ generic_credit: 0, qualification_credit: 0, generation_credit: 0 });
  const [sessionThreshold, setSessionThreshold] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isPremium, setIsPremium] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadProfile = async () => {
      await fetchUserProfile();
      setLoading(false);
    };
    loadProfile();
  }, []);  

  if (loading) {
    return <Spin tip="Loading profile..." />;
  }

  const handleAddAllowedUser = () => {
    addAllowedUser(email);
  };

  const handleSetUserCredits = () => {
    setUserCredits(email, credits);
  };

  const handleAddUserTag = () => {
    addUserTag(email, tagName);
  };  

  const handleRetryFailedJobs = () => {
    retryFailedJobs(email);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleImportCampaign = () => {
    if (selectedFile) {
      importCampaign(email, selectedFile);
    } else {
      console.error("No file selected!");
    }
  };

  const handleExportCampaign = () => {
    exportCampaign();
  };

  const handleCleanupSessions = () => {
    cleanupSessions(email, sessionThreshold);
  };

  const handleSetPremiumUser = () => {
    setPremiumUser(email, isPremium);
  };

  return (
    <div style={{ padding: '20px' }}>
      {profile.isAdmin ? (
        <Card title="Admin Page" bordered={false}>
          <Form layout="vertical">
            <Form.Item label="Email">
              <Input value={email} onChange={(e) => setEmail(e.target.value)} />
            </Form.Item>

            <Form.Item label="Custom Tag Name">
              <Input value={tagName} onChange={(e) => setTagName(e.target.value)} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" onClick={handleAddUserTag}>Add User Tag</Button>
            </Form.Item>            

            <Form.Item label="Session Id Threshold">
              <Input type="number" value={sessionThreshold} onChange={(e) => setSessionThreshold(e.target.value)} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" onClick={handleCleanupSessions}>Cleanup Sessions</Button>
            </Form.Item>

            <Form.Item label="Generic Credit">
              <Input type="number" value={credits.generic_credit} onChange={(e) => setCredits({ ...credits, generic_credit: e.target.value })} />
            </Form.Item>

            <Form.Item label="Qualification Credit">
              <Input type="number" value={credits.qualification_credit} onChange={(e) => setCredits({ ...credits, qualification_credit: e.target.value })} />
            </Form.Item>

            <Form.Item label="Generation Credit">
              <Input type="number" value={credits.generation_credit} onChange={(e) => setCredits({ ...credits, generation_credit: e.target.value })} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" onClick={handleAddAllowedUser}>Add Allowed User</Button>
            </Form.Item>

            <Form.Item>
              <Button type="primary" onClick={handleSetUserCredits}>Set User Credits</Button>
            </Form.Item>

            <Form.Item>
              <Button type="primary" onClick={handleRetryFailedJobs}>Retry Failed Jobs</Button>
            </Form.Item>

            <Form.Item>
              <Button type="primary" onClick={handleExportCampaign}>Export Campaign</Button>
            </Form.Item>

            <Form.Item label="Import Campaign">
              <input type="file" onChange={handleFileChange} />
              <Button type="primary" onClick={handleImportCampaign}>Import Campaign</Button>
            </Form.Item>

            <Form.Item label="Set Premium User">
              <Switch checked={isPremium} onChange={setIsPremium} />
              <Button type="primary" onClick={handleSetPremiumUser} style={{ marginLeft: '10px' }}>
                Set Premium User
              </Button>
            </Form.Item>
          </Form>
        </Card>
      ) : (
        <p>You do not have permission to access this page.</p>
      )}
    </div>
  );
};

export default AdminPage;
