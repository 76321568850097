import React, { useState, useEffect } from 'react';
import { Form, Spin, notification, Button, message, Typography, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import CompanyInfoForm from './CompanyInfoForm';
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';
import './GettingStartedPage.css';

const { Title } = Typography;

const GettingStartedPage = () => {
  const [form] = Form.useForm();
  const { createBusinessContext, getBusinessContext } = useDocumentPageHook();
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAndSetBusinessContext() {
      setIsLoading(true);
      try {
        const response = await getBusinessContext();
        if (response) {
          // If the company info is already filled out, redirect to the dashboard
          navigate('/campaign/setup');
        }
      } catch (error) {
        notification.error({
          message: 'Error',
          description: 'Failed to load existing information. Please try again.',
        });
      } finally {
        setIsLoading(false);
      }
    }
    fetchAndSetBusinessContext();
  }, [navigate]);

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append('business_name', values.businessName);
    if (values.businessDescription) {
      formData.append('business_description', values.businessDescription);
    }
    if (values.businessLinkedin) {
      formData.append('business_linkedin_url', values.businessLinkedin);
    }
    if (values.businessCompanyUrl) {
      formData.append('business_company_url', values.businessCompanyUrl);
    }

    setIsLoading(true);
    try {
      await createBusinessContext(formData);
      navigate('/settings/company');  // Redirect to dashboard after successful submission
    } catch (error) {
      console.error('Failed to create business context:', error);
      notification.error({
        message: 'Error',
        description: 'Failed to save business context. Please try again.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleValidationError = (errorInfo) => {
    if (errorInfo.errorFields && errorInfo.errorFields.length > 0) {
      const errorMessage = errorInfo.errorFields.map((field) => `${field.errors[0]}`).join(', ');
      message.error(errorMessage);
    } else {
      message.error('Submission failed');
    }
  };

  return (
    <div className="getting-started-page">
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <div
          style={{
            minWidth: '792px',
            margin: '0 auto',
            padding: '22px 66px',
            backgroundColor: '#fff',
            borderRadius: '16px',
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            minHeight: '0',
          }}
        >
          <Row justify="space-between" align="middle">
            <Col>
              <Title level={3}>Welcome to Dealight!</Title>
            </Col>
          </Row>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            onFinishFailed={handleValidationError}
          >
            <CompanyInfoForm form={form} />
            <div className="steps-action" style={{ textAlign: 'right', marginTop: 24 }}>
            <Button type="primary" onClick={() => form.validateFields().then(handleSubmit).catch(handleValidationError)}>                Submit
              </Button>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

export default GettingStartedPage;
