import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Modal, Tabs, Tooltip, Table, Image, Space, Divider, Row, Col, Collapse, List, Descriptions, Typography, Tag, Card, Button, message } from 'antd';
import { LinkedinOutlined, InfoCircleOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;
const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

const CompanyDetailsPage = ({ visible, onClose, record, showEmail }) => {
  if (!record) return null;
  const lead = record.company_data;
  const contactPerson = lead?.contact_people ? lead.contact_people[0] : {};

  const logo_name = (
    <>
      <Space>
        <Image
          width={40}
          height={40}
          src={lead.company_logo_url || '/person_icon.png'}
          style={{ borderRadius: '50%' }}
          alt="logo"
        />
        {lead.company_name}
      </Space>
    </>
  );

  const specialties = lead.additional_info?.company_services?.map(service => (
    <Tag key={service.id} color="blue">{service.specialty}</Tag>
  ));

  const recentPosts = lead.additional_info?.recent_updates?.map(post => (
    <Card key={post.id} style={{ marginBottom: 16 }}>
      <Paragraph>{logo_name}:</Paragraph>
      <Paragraph>{post.description}</Paragraph>
      <Paragraph>
        <span style={{ float: 'right' }}><strong>Date:</strong> {post.date} ago</span>
      </Paragraph>
    </Card>
  ));

  const contactInfo = [
    {
      key: '1',
      name: (
        <Space>
          <Image
            width={40}
            height={40}
            src={contactPerson?.logo_url || '/person_icon.png'}
            style={{ borderRadius: '50%' }}
            alt="person"
          />
          {contactPerson?.name || 'N/A'}
        </Space>
      ),
      email: contactPerson?.email_address || 'N/A',
      title: contactPerson?.title || 'N/A',
      url: contactPerson?.url || '',
      tag: (
        <Tag color={lead?.email?.personalized === "PERSONALIZED" ? "green" : "red"}>
          {lead?.email?.personalized}
        </Tag>
      )
    }
  ];

  const contactColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Message Level',
      dataIndex: 'tag',
      key: 'tag',
    },
    {
      title: 'LinkedIn',
      key: 'linkedin',
      render: (_, record) => (
        <a href={record.url} target="_blank" rel="noopener noreferrer">
          <LinkedinOutlined style={{ fontSize: '24px', color: '#0077b5' }} />
        </a>
      ),
    },
  ];

  const formatMessageBody = (body) => {
    return { __html: body?.replace(/\n/g, '<br />') };
  };  

  const copyToClipboard = () => {
    const textToCopy = lead.email.message?.replace(/\\n/g, '\n');
    navigator.clipboard.writeText(textToCopy).then(() => {
      message.success('Message copied to clipboard');
    }).catch(() => {
      message.error('Failed to copy message');
    });
  }; 
  
  const renderInsights = (insights) => {
    return insights.key_questions?.map((question, index) => (
      <div key={index} style={{ marginBottom: 16 }}>
        <strong>Q: {question.question}</strong>
        <div>
          <span>A: {Array.isArray(question.answer) ? question.answer?.map((ans, idx) => (
            <div key={idx}>{Object.entries(ans).map(([key, value]) => (
              <div key={key}>
                <strong>{key}: </strong>{value}
              </div>
            ))}</div>
          )) : question.answer}. </span>
          <span>
            {question.short_reply}
          </span>
          <Tooltip
            title={
              <div dangerouslySetInnerHTML={{ __html: question.additional_insights.join('<br /><br>') }} />
            }
          >
            <Button shape="circle" icon={<InfoCircleOutlined />} style={{ marginLeft: 8 }} />
          </Tooltip>
        </div>
        <Divider />
        {question.reference && question.reference.length > 0 && (
          <div style={{ marginTop: 8 }}>
            {question.reference.map((ref, idx) => (
              <Tag color="blue" key={idx}>
                <a href={ref} target="_blank" rel="noopener noreferrer">{ref}</a>
              </Tag>
            ))}
          </div>
        )}
      </div>
    ));
  };

  return (
    <Modal
      title={logo_name}
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={1300}
      bodyStyle={{ maxHeight: '80vh', overflowY: 'auto' }} // Allow scrolling if content overflows
    >
      <Tabs defaultActiveKey="2">
        {showEmail && (
        <TabPane tab="Email" key="1">
          <Table columns={contactColumns} dataSource={contactInfo} pagination={false} />
          <Divider />
          <Row gutter={[16, 16]}>
            <Col span={14}>
              <Card>
                <ReactMarkdown
                className='large-title'>{'**' + lead.email.title?.replace(/\\n/g, '  \n') + '**'}</ReactMarkdown>
                <Divider />
                <div className="message-body" dangerouslySetInnerHTML={formatMessageBody(lead.email.message)}></div>
                <Button type="primary" className="copy-button" onClick={copyToClipboard}>
                  Copy Message
                </Button>                
              </Card>
            </Col>
            <Col span={10}>
              <Card title="Company Insights" bordered={false} style={{ marginBottom: 16 }}>
                <div>{renderInsights(lead.company_insights)}</div>
              </Card>
              <Card title="Contact Insights" bordered={false}>
                <div>{renderInsights(lead.people_insights)}</div>
              </Card>
            </Col>
          </Row>
        </TabPane>
        )}
        <TabPane tab="Company" key="2">
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Name">{lead.company_name}</Descriptions.Item>
            <Descriptions.Item label="Description">{lead.company_description}</Descriptions.Item>
            <Descriptions.Item label="Website">
              <a href={lead.company_website_url} target="_blank" rel="noopener noreferrer">{lead.company_website_url}</a>
            </Descriptions.Item>
            <Descriptions.Item label="LinkedIn">
              <a href={lead.company_linkedin_url} target="_blank" rel="noopener noreferrer">{lead.company_linkedin_url}</a>
            </Descriptions.Item>
            <Descriptions.Item label="Company Size">{lead.company_size}</Descriptions.Item>
            <Descriptions.Item label="Founded">{lead.company_founded}</Descriptions.Item>
            <Descriptions.Item label="Industry">{lead.company_industry}</Descriptions.Item>
            <Descriptions.Item label="Specialties">{specialties}</Descriptions.Item>
          </Descriptions>
          <Title level={4} style={{ marginTop: '16px' }}>Recent Posts</Title>
          <div>{recentPosts}</div>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default CompanyDetailsPage;
