import React, { useState, useEffect } from 'react';
import { List, Avatar, Layout, Card, Button, Tag } from 'antd';
import './Inbox.css';  // Include your custom CSS here
import CompanyDetailsPage from '../LeadsPage/CompanyDetailsPage';  // Assume you have this component
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';

const { Sider, Content } = Layout;

const Inbox = () => {
  const { getReachOutDetails } = useDocumentPageHook();

  const [data, setData] = useState([]);
  const [selectedThread, setSelectedThread] = useState(null);
  const [detailsModalVisible, setDetailsModalVisible] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);

  useEffect(() => {
    // Fetch data from the backend
    const fetchData = async () => {
      const details = await getReachOutDetails('inbox');
      if (details) {
        setData(details);
      }
    };

    fetchData();
  }, []);

  const handleThreadSelect = (thread) => {
    setSelectedThread(thread);
  };

  const formatMessageBody = (body) => {
    return { __html: body.replace(/\n/g, '<br />') };
  };  

  const handleDetailsClick = (record) => {
    setSelectedLead(record);
    setDetailsModalVisible(true);
  };

  return (
    <Layout style={{ height: '80vh', padding:'20px', overflowY:'visible' }}>
      <Sider width={300} style={{ background: '#fff', padding: '20px', borderRight: '1px solid #d9d9d9', overflowY:'auto'}}>
      <List
          itemLayout="horizontal"
          dataSource={data.sort((a, b) => b.company_data?.relevance.relevance - a.company_data?.relevance.relevance)}
          renderItem={item => (
            <List.Item
            className={`clickable-item ${selectedThread === item ? 'selected' : ''}`}
            onClick={() => handleThreadSelect(item)}
            style={selectedThread === item ? { backgroundColor: '#f5f5f5' } : {}}
          > 
          <List.Item.Meta
                avatar={<Avatar src={item.company_data?.company_logo_url || 'https://via.placeholder.com/150'} />}
                title={                  
                    item.company_data?.company_name || 'Unknown Company'
                }
                description={
                  <div>
                  <span>Score:</span>
                  <Tag color={item.company_data?.relevance.relevance === 0 ? 'red' : 'green'} style={{ marginLeft: '10px' }}>
                    {item.company_data?.relevance.tag}
                  </Tag> 
                  </div>                
                }
              />
            </List.Item>
          )}
        />
      </Sider>
      <Layout style={{ height: '80vh', overflowY:'visible' }}>
        <Content style={{ padding: '20px', background: '#fff', overflowY: 'auto' }}>
          {selectedThread ? (
            <>
              <div className="thread-header" style={{ marginBottom: '20px' }}>
                <Avatar src={selectedThread.company_data?.company_logo_url || 'https://via.placeholder.com/150'} />
                <Button type="link" onClick={() => handleDetailsClick(selectedThread)}>
                  {selectedThread.company_data?.company_name || 'Unknown Company'}
                </Button>
              </div>
              <List
                itemLayout="vertical"
                dataSource={selectedThread.email_detail?.messages || []}
                renderItem={message => (
                  <List.Item key={message.message_id}>
                    <Card
                      className={`message-box ${message.message_type !== 0 ? 'user-message' : 'contact-message'}`}
                      style={{
                        alignSelf: message.message_type !== 0 ? 'flex-start' : 'flex-end',
                        backgroundColor: message.message_type !==0 ? '#f5f5f5' : '#e0e0e0',
                        marginLeft: message.message_type !== 0 ? 0 : 'auto',
                        marginRight: message.message_type !== 0 ? 'auto' : 0,
                        maxWidth: '70%',
                        padding: '10px',
                        marginBottom: '10px'
                      }}
                    >
                      <div className="message-sender">{message.sender}</div>
                      <div className="message-body" dangerouslySetInnerHTML={formatMessageBody(message.body)}></div>
                      <div className="message-date">{new Date(message.sent_at).toLocaleString()}</div>
                    </Card>
                  </List.Item>
                )}
              />
            </>
          ) : (
            <div>Select a thread to view messages</div>
          )}
        </Content>
      </Layout>
      <CompanyDetailsPage
        visible={detailsModalVisible}
        onClose={() => setDetailsModalVisible(false)}
        record={selectedLead}
      />
    </Layout>
  );
};

export default Inbox;

