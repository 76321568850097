import React, { useState, useEffect } from 'react';
import { List, Avatar, Layout, Card, Button, Input, Form, Table, Row, Space, Col, Typography, Tooltip, Tag, Divider, Popover } from 'antd';
import { LinkedinOutlined, InfoCircleOutlined } from '@ant-design/icons';
import './Inbox.css';
import OutReachDetailsPage from '../LeadsPage/OutReachDetailsPage';
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';

const { Sider, Content } = Layout;
const { TextArea } = Input;
const { Title, Paragraph } = Typography;

const PendingInbox = () => {
  const { getReachOutDetails, approveAndSendInitialMessage } = useDocumentPageHook();

  const [data, setData] = useState([]);
  const [selectedThread, setSelectedThread] = useState(null);
  const [detailsModalVisible, setDetailsModalVisible] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const fetchData = async () => {
    const details = await getReachOutDetails('pending');
    if (details) {
      setData(details);
      if (details.length > 0) {
        handleThreadSelect(details[0]);
      }      
    }
  };  
  useEffect(() => {
    fetchData();
  }, []);

  const handleThreadSelect = (thread) => {
    setSelectedThread(thread);
    form.setFieldsValue({
        subject: '',
        message_text: ''
    });    
    if (thread.email_detail?.messages?.length > 0) {
      const firstMessage = thread.email_detail.messages[0];
      form.setFieldsValue({
        subject: firstMessage.subject,
        message_text: firstMessage.body
      });
    }
  };

  const handleDetailsClick = (record) => {
    setSelectedLead(record);
    setDetailsModalVisible(true);
  };

  const handleApprove = async () => {
    const values = await form.validateFields();
    const email_detail_id = selectedThread.email_detail.email_id;
    setIsLoading(true);
    await approveAndSendInitialMessage(email_detail_id, values.subject, values.message_text);
    setSelectedThread(null);
    setIsLoading(false);
    await fetchData();
  };

  const renderInsights = (insights) => {
    return insights.key_questions.map((question, index) => (
      <div key={index} style={{ marginBottom: 16 }}>
        <strong>Q: {question.question}</strong>
        <div>
          <span>A: {Array.isArray(question.answer) ? question.answer.map((ans, idx) => (
            <div key={idx}>{Object.entries(ans).map(([key, value]) => (
              <div key={key}>
                <strong>{key}: </strong>{value}
              </div>
            ))}</div>
          )) : question.answer}</span>
          <Tooltip
            title={
              <div dangerouslySetInnerHTML={{ __html: question.additional_insights.join('<br /><br>') }} />
            }
            trigger={'hover'}
          >
            <Button shape="circle" icon={<InfoCircleOutlined />} style={{ marginLeft: 8 }} />
            
          </Tooltip>
        </div>
        {question.reference && question.reference.length > 0 && (
          <div style={{ marginTop: 8 }}>
            {question.reference.map((ref, idx) => (
              <Tag color="blue" key={idx}>
                <a href={ref} target="_blank" rel="noopener noreferrer">{ref}</a>
              </Tag>
            ))}
          </div>
        )}
        <Divider />

      </div>
    ));
  };

  const renderFollowUpMessages = (messages) => {
    const formattedMessages = messages?.slice(0, 2).map((message, index) => (
      <div key={index} style={{ marginBottom: 16 }}>
        <p>{message.split('\n').map((part, idx) => (
          <span key={idx}>
            {part}
            <br />
          </span>
        ))}</p>
        <Divider />
      </div>
    ));
  
    return <div>{formattedMessages}</div>;
  };
  

  const contactInfo = selectedThread?.company_data?.contact_people ? selectedThread.company_data.contact_people[0] : {};

  const contactColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: () => (
        <Space>
          <Avatar
            src={contactInfo.logo_url || '/person_icon.png'}
            style={{ borderRadius: '50%' }}
            alt="person"
          />
          {contactInfo.name || 'N/A'}
        </Space>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: () => contactInfo.email_address || 'N/A',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: () => contactInfo.title || 'N/A',
    },
    {
      title: 'Message Level',
      dataIndex: 'tag',
      key: 'tag',
      render: () => (
        <Tag color={selectedThread?.company_data?.email?.personalized === "PERSONALIZED" ? "green" : "red"}>
          {selectedThread?.company_data?.email?.personalized}
        </Tag>
      ),
    },
    {
      title: 'LinkedIn',
      key: 'linkedin',
      render: () => (
        <a href={contactInfo.url} target="_blank" rel="noopener noreferrer">
          <LinkedinOutlined style={{ fontSize: '24px', color: '#0077b5' }} />
        </a>
      ),
    },
  ];

  return (
    <Layout style={{ height: '81vh', padding: '20px', overflowY: 'visible' }}>
      <Sider width={300} style={{ background: '#fff', padding: '20px', borderRight: '1px solid #d9d9d9', overflowY: 'auto' }}>
        <List
          itemLayout="horizontal"
          //dataSource={data.sort((a, b) => b.company_data?.relevance.relevance - a.company_data?.relevance.relevance)}
          dataSource={data}
          renderItem={item => (
            <List.Item
            className={`clickable-item ${selectedThread === item ? 'selected' : ''}`}
            onClick={() => handleThreadSelect(item)}
            style={selectedThread === item ? { backgroundColor: '#f5f5f5' } : {}}
          >
            <List.Item.Meta
                avatar={<Avatar src={item.company_data?.company_logo_url || 'https://via.placeholder.com/150'} />}
                title={
                  item.company_data?.company_name || 'Unknown Company'
                }
                description={
                  <div>
                    <span>Score:</span>
                    <Tag color={item.company_data?.relevance.relevance === 0 ? 'red' : 'green'} style={{ marginLeft: '10px' }}>
                      {item.company_data?.relevance.tag}
                    </Tag>
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </Sider>
      <Layout style={{ height: '81vh', overflowY: 'visible' }}>
        <Content style={{ padding: '20px', background: '#fff', overflowY: 'auto' }}>
          {selectedThread ? (
            <>
              <div className="thread-header" style={{ marginBottom: '20px' }}>
                <Avatar src={selectedThread.company_data?.company_logo_url || 'https://via.placeholder.com/150'} />
                <Button type="link" onClick={() => handleDetailsClick(selectedThread)}>
                  {selectedThread.company_data?.company_name || 'Unknown Company'}
                </Button>
              </div>
              <Table columns={contactColumns} dataSource={[contactInfo]} pagination={false} />
              <Divider />
              
              <Row gutter={[16, 16]}>
                <Col span={14}>               
                  <Form form={form} layout="vertical">
                    <Form.Item name="subject" label="Subject">
                      <Input />
                    </Form.Item>
                    <Form.Item name="message_text" label="Message">
                      <TextArea rows={20} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={handleApprove} style={{ float: 'right' }} disabled={isLoading}>
                          Approve & Send
                        </Button>
                        <Popover
                    content={renderFollowUpMessages(selectedThread.company_data.email.follow_up_messages)}
                    title="Follow-up Messages"
                    trigger="click"
                  >
                    <Button style={{ marginRight: '10px', float: 'right' }}>Example follow-ups</Button>
                  </Popover>                           
                    </Form.Item>
                  </Form>
                </Col>
                <Col span={10}>
                  <Card title="Company Insights" bordered={false} style={{ marginBottom: 16 }}>
                    <div>{renderInsights(selectedThread.company_data?.company_insights)}</div>
                  </Card>
                  <Card title="Contact Insights" bordered={false}>
                    <div>{renderInsights(selectedThread.company_data?.people_insights)}</div>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <div>Select a thread to view details</div>
          )}
        </Content>
      </Layout>
      <OutReachDetailsPage
        visible={detailsModalVisible}
        onClose={() => setDetailsModalVisible(false)}
        record={selectedLead}
      />
    </Layout>
  );
};

export default PendingInbox;

