import React, { useState, useEffect } from 'react';
import { Form, Button, Spin, Typography, Row, Col, Tag, Modal, Table, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import Notification from '../Notification/NotificationMessage';
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';
import CompanyTargetForm from '../HomePage/CompanyTargetForm';
import dayjs from 'dayjs';
import utc from 'dayjs-plugin-utc';
import './PreferencePage.css';
import NewTaskPopover from '../LeadsPage/NewTaskPopover';
import useProfilePageHook from '../ProfilePage/ProfilePageHook';
import ImportCSVModal from '../LeadsPage/ImportCSVModal';
import QualifyLeadsPopover from '../LeadsPage/QualifyLeadsPopover';


dayjs.extend(utc);

const { Title, Text } = Typography;

const CompanyTargetPage = ({
  mode = 'default', // modes: 'default', 'import', 'edit'
  data = {}, // data for different modes, like CSV data for 'import' mode
  onCSVSubmit = () => {}, // callback for CSV submit in 'import' mode
}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { createBusinessContext, getBusinessContext, initiateSystemTask, pollSystemTasks } = useDocumentPageHook();
  const { generationCredit, qualificationCredit, fetchUserProfile } = useProfilePageHook();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showSampleModal, setShowSampleModal] = useState(false);

  useEffect(() => {
    fetchAndSetBusinessContext();
  }, [form]);

  const fetchAndSetBusinessContext = async () => {
    setIsLoading(true);
    try {
      const response = await getBusinessContext();
      await fetchUserProfile();
      if (response && response.campaign) {
        const campaign = response.campaign;

        form.setFieldsValue({
          name: campaign.name,
          minSize: campaign.company_criteria.companySize?.min,
          maxSize: campaign.company_criteria.companySize?.max,
          location: campaign.company_criteria.location,
          industry: campaign.company_criteria.industry,
          keywords: campaign.company_criteria?.keywords,
          foundedYear: campaign.company_criteria.founded_year,
          emailTemplateSubject: campaign.company_criteria?.email_info?.emailTemplateSubject,
          emailTemplateName: campaign.company_criteria?.email_info?.emailTemplateName,
          emailTemplateTitle: campaign.company_criteria?.email_info?.emailTemplateTitle,
          emailTemplateValueProp: campaign.company_criteria?.email_info?.emailTemplateValueProp,
          emailCallToAction: campaign.company_criteria.emailCallToAction,
          keyQuestions: campaign.company_criteria.key_questions?.map(q => ({
            question: q.question,
            research_instruction: q.research_instruction
          })),
          services: campaign.company_criteria.services?.map(q => ({
            serviceName: q.serviceName,
            serviceDescription: q.serviceDescription
          })),
          needFunding: campaign.company_criteria.needFunding,
          lastFundingDate: campaign.company_criteria.lastFundingDate ? dayjs.utc(campaign.company_criteria.lastFundingDate) : null,
          contactPreference: campaign.contact_people_criteria.contact_preferences,
          contactKeyQuestions: campaign.contact_people_criteria.key_questions?.map(q => ({
            question: q.question
          })),
        });

        setIsEditMode(false);
        setInitialLoad(false);
      } else {
        setIsEditMode(true);
      }
    } catch (error) {
      console.error('Failed to fetch business context:', error);
      Notification.error('Failed to load existing information. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditClick = () => {
    if (isEditMode) {
      form.validateFields().then(handleSubmit).catch(handleValidationError);
    } else {
      setIsEditMode(true);
    }
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();
    const name = values.name;
    const product_info = {
    };
    const email_info = {
      emailTemplateSubject: values.emailTemplateSubject,
      emailTemplateName: values.emailTemplateName,
      emailTemplateTitle: values.emailTemplateTitle,
      emailTemplateValueProp: values.emailTemplateValueProp,      
    }
    const company_criteria = {
      companySize: {
        min: values.minSize,
        max: values.maxSize
      },
      services: values.services,
      keywords: values.keywords,
      location: values.location,
      industry: values.industry,
      founded_year: values.foundedYear,
      key_questions: values.keyQuestions,
      needFunding: values.needFunding,
      email_info: email_info,
      emailCallToAction: values.emailCallToAction,
      lastFundingDate: values.lastFundingDate ? values.lastFundingDate.toISOString() : null
    };
    const contact_people_criteria = {
      contact_preferences: values.contactPreference,
      key_questions: values.contactKeyQuestions
    };
    const campaign = { name, product_info, company_criteria, contact_people_criteria };

    formData.append('campaign', JSON.stringify(campaign));

    try {
      await createBusinessContext(formData);
      setIsEditMode(false);
    } catch (error) {
      console.error('Failed to create business context:', error);
      Notification.error('Failed to save business context. Please try again.');
    }
  };

  const handleValidationError = (errorInfo) => {
    const errorMessage = errorInfo.errorFields.map(field => `${field.name[0]}: ${field.errors[0]}`).join(', ');
    message.error(errorMessage);
  };

  const handleInitiateTask = async (urlCount) => {
    const session_id = await initiateSystemTask(urlCount);
    if (session_id) {
      pollSystemTasks(session_id);
    }
  };

  const handleImportCSVClick = () => {
    setShowModal(true);
  };

  const handleSampleClick = () => {
    setShowSampleModal(true);
  };

  const renderHeaderSection = () => {
    if (mode === 'import') {
      return (
        <Row justify="space-between" align="middle">
          <Col>
            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <Title level={3} style={{ margin: 0 }}>Campaign Setup</Title>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop:'5px' }}>
                {data.fileName.length > 15 ? `${data.fileName.slice(0, 15)}...` : data.fileName}
                <EditOutlined onClick={handleImportCSVClick} style={{ cursor: 'pointer' }} />
                <span>|</span>
                <span onClick={handleSampleClick} style={{ cursor: 'pointer', color: '#1890ff' }}>
                  Found {data.uploadedData?.length || 0} records
                </span>
              </div>
            </div>
          </Col>
          <Col>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0px' }}>
              <Tag color="default" style={{ padding: '8px 16px' }}>
                <Text strong>Qualification Credit: {qualificationCredit}</Text>
              </Tag>
              <Button
                type="primary"
                onClick={handleEditClick}
                style={{ marginRight: '8px' }}
              >
                {isEditMode ? 'Save' : 'Edit'}
              </Button>
              <QualifyLeadsPopover
                uploadedData={data.uploadedData} // Pass the validated data
                isProcessing={isEditMode}
                outOfCredit={qualificationCredit === 0} // Adjust based on your logic
              />
            </div>
          </Col>
        </Row>
      );
    }
  
    return (
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={3}>Campaign Setup</Title>
        </Col>
        <Col>
          <Tag color="default" style={{ padding: '8px 16px' }}>
            <Text strong>Credit: {generationCredit}</Text>
          </Tag>
          <Button
            type="primary"
            onClick={handleEditClick}
            style={{ marginRight: '8px', marginTop: '15px' }}
          >
            {isEditMode ? 'Save' : 'Edit'}
          </Button>
          <NewTaskPopover
            onInitiateTask={handleInitiateTask}
            maxURLCount={1000}
            isProcessing={isEditMode}
            outOfCredit={generationCredit === 0}
          />
        </Col>
      </Row>
    );
  };
  

  return (
    <div className="preference-page">
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <div style={{
          minWidth: '792px',
          margin: '0 auto',
          padding: '22px 66px',
          backgroundColor: '#fff',
          borderRadius: '16px',
          boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        }}>
          {renderHeaderSection()}
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            disabled={!isEditMode}
          >
            <CompanyTargetForm form={form} mode={isEditMode} isTask={false} isImport={mode === 'import'} />
          </Form>
          {mode === 'import' && (
            <>
              <ImportCSVModal onSubmit={onCSVSubmit} visible={showModal} setVisible={setShowModal} />
              <Modal
                title="Sample Records"
                visible={showSampleModal}
                onCancel={() => setShowSampleModal(false)}
                footer={null}
                width={800}
              >
                <Row justify="left" align="middle" style={{ marginBottom: '10px', gap: '15px' }}>
                  <Col>
                    <Text type="secondary">Sample:</Text>
                  </Col>
                  <Col>
                    <Tag color="green">{data.uploadedData?.length || 0} Validated Records</Tag>
                  </Col>
                </Row>
                <Table
                  columns={[
                    { title: 'Name', dataIndex: 'name', key: 'name' },
                    { title: 'Title', dataIndex: 'title', key: 'title' },
                    { title: 'Company Domain', dataIndex: 'companyDomain', key: 'companyDomain' },
                    { title: 'Email', dataIndex: 'email', key: 'email' },
                    { title: 'LinkedIn', dataIndex: 'linkedinUrl', key: 'linkedinUrl' },
                  ]}
                  dataSource={data.uploadedData}
                  pagination={{ pageSize: 10 }}
                  rowKey={(record, index) => index}
                  scroll={{ x: 'max-content' }}  // Enable horizontal scrolling                  
                  style={{ marginBottom: '20px' }}
                />
              </Modal>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default CompanyTargetPage;
