import React, { useEffect } from 'react';
import { AuthProvider, useAuth, getToken } from './components/Login/AuthContext';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import MainLayout from './components/Layout/MainLayout';
import { DocumentProvider } from './components/DocumentPage/DocumentContext';
import LoginPage from './components/Login/LoginPage';
import GettingStartedPage from './components/HomePage/GettingStartedPage';
import PricingPage from './components/PricingPage/PricingPage';
import ContactUsPage from './components/ContactUsPage/ContactUsPage';
import MyLeadsPage from './components/LeadsPage/MyLeadsPage';
import TaskDetailPage from './components/LeadsPage/TaskDetailPage';
import TasksPage from './components/LeadsPage/TasksPage';
import SetupOverviewPage from './components/SettingsPage/SetupOverviewPage';
import ImportCSVPage from './components/SettingsPage/ImportCSVPage';
import LeadsUploadPage from './components/LeadsPage/LeadsUploadPage';
import CompanyInfoPage from './components/SettingsPage/CompanyInfoPage';
import CompanyTargetPage from './components/SettingsPage/CompanyTargetPage';
import AdminPage from './components/AdminBar/AdminPage';
import IntegrationPage from './components/Integrations/IntegrationPage';
import Inbox from './components/EmailPage/Inbox';
import PendingInbox from './components/EmailPage/PendingInbox';
import UsagePage from './components/DocumentPage/UsagePage';

const AuthenticatedLayout = ({ children }) => {
  const token = getToken();
  const { validateToken, handleLogout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      const isValid = await validateToken();
      if (!isValid) {
        handleLogout();
        navigate('/');
      }
    };

    if (token) {
      checkToken();
    } else {
      navigate('/'); // No token, redirect to login page
    }
  }, [token, validateToken, handleLogout, navigate]);

  return token ? children : null; // Render children only if there's a token
};

const AppRoutes = () => {
  const location = useLocation();
  const hideSider = location.pathname.includes('/getting_started')

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="*" element={
        <AuthenticatedLayout>
          <DocumentProvider>
            <MainLayout hideSider={hideSider}>
              <Routes>  
                <Route path="/getting_started" element={<GettingStartedPage />} />
                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/contact_us" element={<ContactUsPage />} />
                <Route path="/campaign/setup/list" element={<CompanyTargetPage />} />
                <Route path="/campaign/setup/import" element={<ImportCSVPage />} />

                <Route path="/campaign/setup" element={<SetupOverviewPage />} />

                <Route path="/campaign/workspace" element={<LeadsUploadPage />} />
                <Route path="/campaign/tasks" element={<TasksPage />} />
                <Route path="/campaign/leads" element={<MyLeadsPage />} />
                <Route path="/campaign/tasks/:sessionId" element={<TaskDetailPage />} /> // Add this route
                <Route path="/settings/company" element={<CompanyInfoPage />} />
                <Route path="/admin" element={<AdminPage />} />
                <Route path="/settings/integrations" element={<IntegrationPage />} />
                <Route path="/usage" element={<UsagePage />} />

                <Route path="/inbox" element={<Inbox />} />
                <Route path="/pending_approval" element={<PendingInbox />} />

              </Routes>
            </MainLayout>
          </DocumentProvider>
        </AuthenticatedLayout>
      } />
    </Routes>
  );  
};



const App = () => {
  return (
    <AuthProvider>
      <Router>
        <AppRoutes />
      </Router>
    </AuthProvider>
  );
};

export default App;