import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Typography, Tabs, Table, Statistic, Row, Col, Tooltip, Form, Tag, message } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';
import CompanyTargetForm from '../HomePage/CompanyTargetForm';
import ResumeTaskPopover from './ResumeTaskPopover';
import dayjs from 'dayjs';
import utc from 'dayjs-plugin-utc';
import useProfilePageHook from '../ProfilePage/ProfilePageHook';


dayjs.extend(utc);

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const TaskDetailPage = () => {
  const { pollTime, sessions, getBusinessContext, updateTask, pollSystemTasks, resumeAnalysisTask } = useDocumentPageHook();
  const { generationCredit, qualificationCredit, fetchUserProfile } = useProfilePageHook();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [sessionId, setSessionId] = useState(state?.session_id);
  const [session, setSession] = useState(state?.session);
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [form] = Form.useForm();
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      await fetchUserProfile();
    };
    fetchProfile();
  }, []);

  useEffect(() => {
    if (sessions) {
      const sessionData = sessions.find(session => session.session_id === sessionId);
      setSession(sessionData);
      const campaign = sessionData?.campaign_snapshot;
      if (campaign) {
        form.setFieldsValue({
          name: campaign.name,
          minSize: campaign.company_criteria.companySize?.min,
          maxSize: campaign.company_criteria.companySize?.max,
          location: campaign.company_criteria.location,
          industry: campaign.company_criteria.industry,
          keywords: campaign.company_criteria?.keywords,
          foundedYear: campaign.company_criteria.founded_year,
          emailTemplateSubject: campaign.company_criteria?.email_info?.emailTemplateSubject,
          emailTemplateName: campaign.company_criteria?.email_info?.emailTemplateName,
          emailTemplateTitle: campaign.company_criteria?.email_info?.emailTemplateTitle,
          emailTemplateValueProp: campaign.company_criteria?.email_info?.emailTemplateValueProp,
          emailCallToAction: campaign.company_criteria.emailCallToAction,            
          keyQuestions: campaign.company_criteria.key_questions?.map(q => ({
            question: q.question,
            research_instruction: q.research_instruction
          })),
          services: campaign.company_criteria.services?.map(q => ({
            serviceName: q.serviceName,
            serviceDescription: q.serviceDescription
          })),            
          needFunding: campaign.company_criteria.needFunding,
          lastFundingDate: campaign.company_criteria.lastFundingDate ? dayjs.utc(campaign.company_criteria.lastFundingDate) : null,
          contactPreference: campaign.contact_people_criteria.contact_preferences,
          contactKeyQuestions: campaign.contact_people_criteria.key_questions?.map(q => ({
            question: q.question
          })),
        });
      }
    }
  }, [pollTime, sessions, sessionId, form]);

  if (!session) {
    return;
  }

  const handleEditClick = () => {
    if (isEditMode) {
      form.validateFields().then(handleSubmit).catch(handleValidationError);
    } else {
      setIsEditMode(true);
    }
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();
    const name = values.name;
    const product_info = {};
    const email_info = {
      emailTemplateSubject: values.emailTemplateSubject,
      emailTemplateName: values.emailTemplateName,
      emailTemplateTitle: values.emailTemplateTitle,
      emailTemplateValueProp: values.emailTemplateValueProp,
    };
    const company_criteria = {
      companySize: {
        min: values.minSize,
        max: values.maxSize
      },
      services: values.services,
      keywords: values.keywords,
      location: values.location,
      industry: values.industry,
      founded_year: values.foundedYear,
      key_questions: values.keyQuestions,
      needFunding: values.needFunding,
      email_info: email_info,
      emailCallToAction: values.emailCallToAction,
      lastFundingDate: values.lastFundingDate ? values.lastFundingDate.toISOString() : null
    };
    const contact_people_criteria = {
      contact_preferences: values.contactPreference,
      key_questions: values.contactKeyQuestions
    };
    const campaign = { name, product_info, company_criteria, contact_people_criteria };

    formData.append('campaign', JSON.stringify(campaign));
    formData.append('session_id', sessionId);

    try {
      await updateTask(formData);
      setIsEditMode(false);
    } catch (error) {
      console.error('Failed to create business context:', error);
      message.error('Failed to save business context. Please try again.');
    }
  };

  const handleValidationError = (errorInfo) => {
    if (errorInfo.errorFields && errorInfo.errorFields.length > 0) {
      const errorMessage = errorInfo.errorFields.map(field => `${field.name[0]}: ${field.errors[0]}`).join(', ');
      message.error(errorMessage);
    } else {
      console.log(errorInfo);
      message.error('Form submission failed');
    }
  };

  const handleResumeTask = async (sessionId, urlCount) => {
    const response = await resumeAnalysisTask(sessionId, urlCount);
    if (response) {
      pollSystemTasks(sessionId);
    }  
  };  

  const renderHeaderSection = () => {
    if (session?.research_source === 1) {
      return (
          <Row justify="space-between" align="middle">
          <Col>
            <Title level={3}>Campaign Setup</Title>
          </Col>
          <Col>
          <Tag color="default" style={{ 
            padding: '8px 16px', 
            alignItems: 'center'
          }}>
            <Text strong>Qualification Credit: {qualificationCredit}</Text>
          </Tag>              
            <Button
              type="primary"
              onClick={handleEditClick}
              style={{ marginRight: '8px', marginTop:'15px' }}
            >
              {isEditMode ? 'Save' : 'Edit'}
            </Button>
            <ResumeTaskPopover
              onResumeTask={handleResumeTask}
              maxURLCount={1000}
              isProcessing={session.session_state != 'PAUSED'}
              analyzedURLs={session.analyzed_urls}
              urlsToAnalyze={session.total_urls_to_process}
              sessionId={sessionId}
              outOfCredit={qualificationCredit==0}
            />
          </Col>
        </Row> 
      );
    }
  
    return (
      <Row justify="space-between" align="middle">
      <Col>
        <Title level={3}>Campaign Setup</Title>
      </Col>
      <Col>
      <Tag color="default" style={{ 
        padding: '8px 16px', 
        alignItems: 'center'
      }}>
        <Text strong>Credit: {generationCredit}</Text>
      </Tag>              
        <Button
          type="primary"
          onClick={handleEditClick}
          style={{ marginRight: '8px', marginTop:'15px' }}
        >
          {isEditMode ? 'Save' : 'Edit'}
        </Button>
        <ResumeTaskPopover
          onResumeTask={handleResumeTask}
          maxURLCount={1000}
          isProcessing={session.session_state != 'PAUSED'}
          analyzedURLs={session.analyzed_urls}
          urlsToAnalyze={session.total_urls_to_process}
          sessionId={sessionId}
          outOfCredit={generationCredit==0}
        />
      </Col>
    </Row> 
    );
  };  

  const statistics = session?.statistics || {};

  return (
    <div style={{
      width: '100%',
      margin: '0 auto',
      padding: '22px 66px',
      backgroundColor: '#fff',
      borderRadius: '16px',
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
      display: 'flex',
      flexDirection: 'column',
      minHeight: '0',
      overflow: 'auto'
    }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Title level={2} style={{ marginTop: 0 }}>Task: {session.name || ''}</Title>
      </div>
      <Tabs defaultActiveKey="1" style={{ width: '100%' }} onChange={setActiveTabKey}>
        <TabPane tab={<span style={{ fontWeight: 'bold' }}>Overview</span>} key="1">
          <div style={{ marginBottom: 16 }}>
            <Row gutter={16}>
              <Col span={5}>
                <Statistic
                  title={<span>Processing <Tooltip title="Number of tasks currently being processed"><InfoCircleOutlined /></Tooltip></span>}
                  value={statistics.Processing}
                />
              </Col>
              <Col span={5}>
                <Statistic
                  title={<span>Complete <Tooltip title="Number of tasks that are completed"><InfoCircleOutlined /></Tooltip></span>}
                  value={statistics.Complete + statistics["Reached out"]}
                />
              </Col>
              <Col span={5}>
                <Statistic
                  title={<span>Error <Tooltip title="Number of tasks that have failed due to error"><InfoCircleOutlined /></Tooltip></span>}
                  value={statistics.Error}
                />
              </Col>
              <Col span={5}>
                <Statistic
                  title={<span>Missing Contact <Tooltip title="Number of tasks missing contact information"><InfoCircleOutlined /></Tooltip></span>}
                  value={statistics["Missing Contact"]}
                />
              </Col>
              <Col span={4}>
                <Statistic
                  title={<span>Out of Credit <Tooltip title="Contact us if you want to check out these companies"><InfoCircleOutlined /></Tooltip></span>}
                  value={statistics["Out of Credit"]}
                />
              </Col>              
            </Row>
          </div>

          {renderHeaderSection()}         
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            disabled={!isEditMode}
          >
            <CompanyTargetForm form={form} mode={isEditMode} isTask={true} isImport={session?.research_source === 1} />
          </Form>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default TaskDetailPage;
